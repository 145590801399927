import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContext from './App.context';
import './App.css';

const Docs = lazy(() => import('./pages'));

const App = () => {
  const [appData, setAppData] = useState({
    apiKey: '',
  });

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const appContextValue = {
    ...appData,
    updateAppData,
  };

  return (
    <div className="App">
      <AppContext.Provider value={appContextValue}>
        <Router>
          <Suspense fallback={<div />}>
            <Docs />
          </Suspense>
        </Router>
      </AppContext.Provider>
    </div>
  );
};

export default App;
